import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import useFetchHowWeWork from "../HowWeWork.hooks";
import * as S from "./Content.styles";
import * as SH from "@shared";
import ReactHtmlParser from "react-html-parser";

export const Content = () => {
  const { data } = useFetchHowWeWork();

  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          {!!data.allStrapiHowWeWorkPage.edges[0].node.repeatedContent &&
            data.allStrapiHowWeWorkPage.edges[0].node.repeatedContent.map(
              ({ title, description, id }, index) => (
                <S.ColCustom className="pr-xl-5" key={id} lg="4">
                  <S.Header colorComponent="whitegray">{index + 1}.</S.Header>
                  <SH.Text.Header2>{title}</SH.Text.Header2>
                  <SH.Text.MarkdownTextBody className="pt-3">
                    {ReactHtmlParser(description)}
                  </SH.Text.MarkdownTextBody>
                </S.ColCustom>
              )
            )}
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};
