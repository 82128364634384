//@ts-ignore

import React from "react";
import * as SH from "@shared";
import { Row, Container, Col } from "react-bootstrap";
import Scheme from "./Scheme.svg";
import useFetchHowWeWork from "./HowWeWork.hooks";

export const OurRecipeForSuccess = () => {
  const { data } = useFetchHowWeWork();

  return (
    <SH.Wrapper.Primary
      colorComponent="whitegray"
      paddingTop={{ mobile: 30, desktop: 40 }}
    >
      <Container>
        <Row>
          <Col className="offset-lg-1" lg="10">
            <SH.Text.Header2 className="text-center pb-5">
              {data.allStrapiHowWeWorkPage.edges[0].node.footer}
            </SH.Text.Header2>
          </Col>
          <Col
            lg="12"
            className="overflow-hidden d-flex justify-content-center pb-5 mb-5"
          >
            <SH.Image
              heightImg={heightImg}
              src={
                data.allStrapiHowWeWorkPage.edges[0].node.imageFooter.localFile
                  .publicUrl
              }
            ></SH.Image>
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const heightImg = {
  desktop: 820,
  mobile: 387,
};
