import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import * as SH from "@shared";
import useFetchHowWeWork from "./HowWeWork.hooks";
import ReactHtmlParser from "react-html-parser";

export const SuccessBox = () => {
  const { data } = useFetchHowWeWork();

  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          <Col lg="8">
            <SH.Wrapper.Box
              className="d-flex justify-content-center align-self-lg-center py-0"
              colorComponent="whitegray"
              heightComponent={{ desktop: 230, mobile: 285 }}
            >
              <Row className="justify-content-center text-center my-auto">
                <Col lg="9">
                  <SH.Text.Header2>
                    {data.allStrapiHowWeWorkPage.edges[0].node.successBox.title}
                  </SH.Text.Header2>
                  <SH.Text.MarkdownTextBody>
                    {ReactHtmlParser(
                      data.allStrapiHowWeWorkPage.edges[0].node.successBox
                        .description
                    )}
                  </SH.Text.MarkdownTextBody>
                </Col>
              </Row>
            </SH.Wrapper.Box>
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};
