import { useStaticQuery, graphql } from "gatsby";

const useFetchHowWeWork = () => {
  const data: TData = useStaticQuery(graphql`
    {
      allStrapiHowWeWorkPage {
        edges {
          node {
            repeatedContent {
              title
              id
              description
            }
            successBox {
              title
              description
            }
            footer
            imageFooter {
              localFile {
                publicUrl
              }
            }
          }
        }
      }
    }
  `);

  return { data };
};

export default useFetchHowWeWork;

type TData = {
  allStrapiHowWeWorkPage: {
    edges: {
      node: {
        repeatedContent: {
          title: string;
          id: number;
          description: string;
        }[];
        successBox: {
          title: string;
          description: string;
        };
        footer: string;
        imageFooter: {
          localFile: {
            publicUrl: string;
          };
        };
      };
    }[];
  };
};
