import styled, { css } from "styled-components";
import * as theme from "@theme";
import * as SH from "@shared";
import { Col } from "react-bootstrap";

export const ColCustom = styled(Col)`
  @media ${theme.breakpoints.desktop} {
    &:nth-of-type(even) {
      margin-top: 105px;
    }
  }
`;

export const Header = styled(SH.Text.Span)`
  ${SH.Text.Header1Css};
  display: block;
`;
